import distance from "@turf/distance";
import * as turf from "@turf/helpers";

export default {
  computed: {
    currentDealAvailableOn: function() {
      if ((this.currentDeal.currentUserAvailableOn == null) || (this.currentDeal.currentUserAvailableOn == "today")) {
        return "today"
      } else if (this.currentDeal.currentUserAvailableOn == "never") {
        return "never"
      } else {
        const today = new Date()
        const availableOn = new Date(this.currentDeal.currentUserAvailableOn)
        if (availableOn > today) {
          return "tomorrow"
        }
        return "today" 
      }
    },
    nearCurrentDealLocation: function() {
      const dealLocation = this.currentDealLocation
      const userLocation = this.$store.getters.currentLocation
      if (!dealLocation || !dealLocation.geom || !dealLocation.geom.coordinates || !userLocation || !userLocation.lat || !userLocation.lng) {
        return false
      }

      const p1 = turf.point([userLocation.lng, userLocation.lat])
      const p2 = turf.point(dealLocation.geom.coordinates)
      const dist = distance(p1, p2, {units: "miles"})
      return (turf.convertLength(dist, "miles", "feet") <= 300) ? true : false
    },
    scanAllowed: function() {
      return (
        // must be logged in
        !this.loggedIn
        // must be an active deal
        || !this.currentDeal.is_active
        // can't scan one's own deal
        || (this.canEdit && !this.isAdmin)
        // can't have a pending in-app claim on the deal
        || (this.currentDeal.currentUserStatus == "pending")
        // can't have already claimed the deal if it's single-use
        || (this.currentDealAvailableOn == "never")
        // must wait 24 hrs to reclaim deal if it's not single-use
        || (this.currentDealAvailableOn == "tomorrow")
        // must be near the physical location
        || (this.isPhysicalLocation && !this.nearCurrentDealLocation)
      ) ? false : true
    },
    reasonScanNotAllowed: function() {
      if (!this.loggedIn) {
        return "You must be logged in to claim deals."
      } else if (!this.currentDeal.is_active) {
        return "This deal is not active and so cannot be claimed."
      } else if (this.canEdit && !this.isAdmin) {
        return "Sorry, you cannot claim your own deal."
      } else if (this.currentDeal.currentUserStatus == "pending") {
        return "It may take up to 24 hours for your claim to be processed."
      } else if (this.currentDealAvailableOn == "never") {
        return "You have already claimed this deal. Only one per customer."
      } else if (this.currentDealAvailableOn == "tomorrow") {
        return "Limit one claim per day. You may claim this deal again tomorrow."
      } else if (this.isPhysicalLocation && !this.nearCurrentDealLocation) {
        return "You must be at the location claim this deal."
      }
      return ""
    }
  },
  methods: {
    parseQrCode(qrCode) {
      const matches = qrCode.match(/^https:\/\/[^/]+\/deal\/([^/]+)/)
      return (matches.length > 1) ? matches[1] : qrCode
    }
  }
}
